<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
import router from "@/router/index.js";

/**
 * Form validation component
 */
export default {
  page: {
    title: "VIP",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Liste des souscriptions VIP",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          active: true,
        },
      ],
      thenDay: "",
      id: "",
      totalRows: 0,
      totalRowsDisable: 0,
      currentPage: 1,
      perPage: 500,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      vipList: [],

      alerteDataDes: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "utilisateur", label: "Utilisateur" },
        { key: "telephone", sortable: true, label: "Téléphone" },
        { key: "date_debut", label: "Début" },
        { key: "date_fin", sortable: true, label: "Date fin" },
        { key: "montant", label: "Montant" },

        //{ key: "action", label: "Actions" },
      ],

      startValue: 1,
      endValue: 500,
      totalValue: "0",
      userChargeLoader: false,
      newVipList: [],
      parBon: 500,
      recherche: "",
      userChargeLoaderCanClose: false,
    };
  },

  async mounted() {
    this.init();
    setTimeout(() => {
      if (this.vipList.length == 0) {
        this.userChargeLoaderCanClose = true;
      }
    }, 2000);
  },

  methods: {
    async init() {
      const vip = await apiRequest("GET", "vip/list-vip", undefined, false);
      console.log(vip);

      if (vip && vip.data) {
        console.log(vip.data);
        this.totalValue = vip.data.total;
        const vipTable = vip.data.data.map((vipData) => {
          return {
            utilisateur:
              vipData.personne.lastname + " " + vipData.personne.firstname,
            telephone: `${vipData.personne.indicatifTel}${vipData.personne.tel}`,
            date_debut: new Date(vipData.dateDebut).toLocaleString("fr-FR"),
            date_fin: new Date(vipData.dateFin).toLocaleString("fr-FR"),

            montant: vipData.montant,
            userId: vipData.personne.id,
          };
        });
        this.vipList = vipTable;

        this.totalRows = this.vipList.length;
        this.totalValue = this.vipList.length;
      }
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;

      this.$v.$touch();
      //this.create();
    },

    tooltipForm() {
      this.submitform = true;
      //this.submitted = true;
      this.formSubmit();
      this.$v.$touch();
    },

    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars

    async actif(page, limit) {
      //Chargement des données

      const data = await apiRequest(
        "GET",
        "admin/trajets/vip?page=" + page + "&limit=" + limit,
        undefined,
        false
      );
      if (data && data.data) {
        const vipTableReserve = data.data.reservations.map((recherche) => {
          if (recherche.client != null && recherche.conducteur != null) {
            return {
              id: recherche.id,
              itinéraire:
                recherche.idTrajet.lieuDepart +
                " " +
                ">>" +
                " " +
                recherche.idTrajet.lieuArrivee,
              conducteur:
                recherche.conducteur.personne.firstname +
                " " +
                recherche.conducteur.personne.lastname,
              conducteurtel:
                recherche.conducteur.personne.tel &&
                recherche.conducteur.personne.tel != ""
                  ? recherche.conducteur.personne.indicatifTel +
                    recherche.conducteur.personne.tel
                  : "",
              userp:
                recherche.client.personne.firstname +
                " " +
                recherche.client.personne.lastname,
              telp:
                recherche.client.personne.tel &&
                recherche.client.personne.tel != ""
                  ? recherche.client.personne.indicatifTel +
                    recherche.client.personne.tel
                  : "",
              date: new Date(recherche.dateReservation).toLocaleString("fr-FR"),
              depart: new Date(
                recherche.idTrajet.dateHeureDepart
              ).toLocaleDateString("fr-FR"),
              state: recherche.statut,
            };
          }
        });

        this.userChargeLoader = false;
        this.newVipList = [];
        vipTableReserve.forEach((element) => {
          if (element.conducteur != null) this.newVipList.push(element);
        });

        this.userChargeLoader = false;
      }
    },

    desactivity(row) {
      this.showdisable = true;
      this.rowCall = row.id;
    },
    async desactive() {
      this.showdisable = false;
      const returnAddSucess = await apiRequest(
        "POST",
        "admin/alerte-active",
        {
          id: this.rowCall,
          active: false,
        },
        false
      );
      if (returnAddSucess && returnAddSucess.data) {
        this.init();
      }
    },
    decrement() {
      this.userChargeLoader = true;

      this.startValue -= this.parBon;
      this.endValue -= this.parBon;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },

    increment() {
      this.userChargeLoader = true;
      this.startValue += this.parBon;
      this.endValue += this.parBon;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },
    incrementToLast() {
      this.userChargeLoader = true;
      this.startValue = this.totalValue - this.parBon;
      this.endValue = this.totalValue;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },
    firstVal() {
      this.userChargeLoader = true;
      this.startValue = 1;
      this.endValue = this.parBon;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },

    async userSearch() {
      this.userChargeLoader = true;
      const dataSearch = await apiRequest(
        "POST",
        "vip/recherche?mots=" ,
        {mots: this.recherche},
        undefined
      );
      if (dataSearch && dataSearch.data) {
        console.log(dataSearch.data);

        const vipTable = dataSearch.data.map((vipData) => {
          return {
            utilisateur:
              vipData.user.personne.lastname + " " + vipData.user.personne.firstname,
            telephone: `${vipData.user.personne.indicatifTel}${vipData.user.personne.tel}`,
            date_debut: new Date(vipData.dateDebut).toLocaleString("fr-FR"),
            date_fin: new Date(vipData.dateFin).toLocaleString("fr-FR"),

            montant: vipData.montant,
            userId: vipData.user.personne.id,
          };
        });
        this.newVipList = vipTable;

        this.totalRows = this.newVipList.length;
        this.userChargeLoader=false
    
      }
    },

    goToDetail(row) {
      router.push(`/user/user_info/${row.userId}`);
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div
            class="containerDot"
            v-if="vipList.length == 0 && userChargeLoaderCanClose == false"
          >
            <span class="loading">Chargement en cours... </span>
            <!--  <div class="dots">
              <div class="dot dot-1"></div>
              <div class="dot dot-2"></div>
              <div class="dot dot-2"></div>
            </div> -->
          </div>
          <div
            class="d-flex align-items-center otherThing"
            v-if="userChargeLoader === true"
          >
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div>
          <div class="card-body">
            <div
              class="row d-flex justify-content-between align-items-center p-2"
            >
              <div>
                <h4 class="card-title mt-4">Total de souscriptions VIP : {{ totalRows }}</h4>
              </div>
              <transition mode="out-in">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <li>
                      <span class="interval"> {{ startValue }}</span> -
                      <span class="interval">{{ endValue }}</span> sur
                      {{ totalValue }}
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                      <button
                        class="myBouton"
                        @click="firstVal()"
                        :disabled="startValue == 1"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Prem"
                      >
                        <ion-icon name="play-skip-back-outline"></ion-icon>
                      </button>
                      <button
                        type="button"
                        class="myBouton"
                        @click="decrement()"
                        :disabled="endValue == this.parBon"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Préc"
                      >
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="increment()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Suiv"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="incrementToLast()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Dern"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="play-skip-forward-outline"></ion-icon>
                      </button>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
            <form>
              <div class="row d-flex justify-content-between mt-4">
                <div class="p-3"></div>
                <!-- Search -->
                <div class="inputCol p-3">
                  <div class="input">
                    <input
                      type="search"
                      id="serchInput"
                      placeholder="Rechercher..."
                      v-model="recherche"
                      @keyup.enter="userSearch"
                    />
                  </div>
                </div>
                <!-- End search -->
              </div>
              <div class="table-responsive">
                <b-table
                  class="table-centered"
                  style="cursor: pointer"
                  :items="newVipList.length != 0 ? newVipList : vipList"
                  :fields="fields"
                  responsive="sm"
    
     
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  @row-clicked="goToDetail"
                >
                  <template
                    v-slot:cell(telephone)="row"
                    style="text-align: center"
                  >
                    <a
                      :href="'https://wa.me/' + row.value"
                      class="text-success"
                      v-b-tooltip.hover
                      title="Envoyer un message whatsapp"
                      target="_blank"
                    >
                      {{ row.value }}
                    </a>
                  </template>
                  <!--  <template
                        v-slot:cell(telp)="row"
                        style="text-align:center;"
                      >
                        <a
                          :href="'https://wa.me/' + row.value"
                          class="text-success"
                          v-b-tooltip.hover
                          title="Envoyer un message whatsapp"
                          target="_blank"
                        >
                          {{ row.value }}
                        </a>
                      </template> -->
                </b-table>
              </div>
              <!--   <div class="row">
                    <div class="col">
                      <div
                        class="dataTables_paginate paging_simple_numbers float-right"
                      >
                        <ul class="pagination pagination-rounded mb-0">
                          pagination 
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                          ></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div> -->
              <div class="row">
                <div class="col">
                  <!--   <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      :aria-controls="trajet"
                    ></b-pagination>
                  </ul>
                </div> -->
                  <transition mode="out-in">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <li>
                          <span class="interval"> {{ startValue }}</span> -
                          <span class="interval">{{ endValue }}</span> sur
                          {{ totalValue }}
                        </li>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <li>
                          <button
                            class="myBouton"
                            @click="firstVal()"
                            :disabled="startValue == 1"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Prem"
                          >
                            <ion-icon name="play-skip-back-outline"></ion-icon>
                          </button>
                          <button
                            type="button"
                            class="myBouton"
                            @click="decrement()"
                            :disabled="endValue == parBon"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Préc"
                          >
                            <ion-icon name="chevron-back-outline"></ion-icon>
                          </button>
                          <button
                            class="myBouton"
                            @click="increment()"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Suiv"
                            :disabled="endValue >= totalValue"
                          >
                            <ion-icon name="chevron-forward-outline"></ion-icon>
                          </button>
                          <button
                            class="myBouton"
                            @click="incrementToLast()"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Dern"
                            :disabled="endValue >= totalValue"
                          >
                            <ion-icon
                              name="play-skip-forward-outline"
                            ></ion-icon>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </transition>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-backdrop"
      centered
      v-model="showdisable"
      title="Voulez-vous vraiment suspendre cet utilisateur ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showdisable = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="desactivity"
          >Oui</b-button
        >
      </div>
    </b-modal>
  </Layout>
</template>

<style>
thead {
  background: #3aaa35;
}
thead div {
  color: #fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 260px;
  height: 35px;
  top: -50%;
  margin-top: 10px;

  flex: 60%;

  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}
.input input:focus {
  border: 3px solid #d6d2f8;
}
.inputCol {
  position: relative;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}
.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -50px;
}
.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: -13%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

.containerDot {
  z-index: 100;
}

.paging_simple_numbers {
  background: #3aaa35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}
.paging_simple_numbers ul li {
  color: white !important;
}

.paging_simple_numbers ul li button {
  color: #fff !important;
}
</style>
